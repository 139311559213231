export const isEmpty = (obj) => {
  return obj == null || Object.keys(obj).length === 0;
};

export const setPultTypeToLocalStorage = (flag) => {
  localStorage.isTestPult = flag;
};

export const getPultTypeFromLocalStorage = () => {
  return toBool(localStorage.isTestPult);
};

export const toBool = (str) => {
  return (str ?? "false").toLowerCase() === "true";
};

export const distinctArray = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};
