import moment from "moment";
export default {
  methods: {
    ddmmyyyy(date, devider) {
      var mm = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();

      return [
        (dd > 9 ? "" : "0") + dd,
        (mm > 9 ? "" : "0") + mm,
        date.getFullYear(),
      ].join(devider);
    },
    /**
     * Фоматирует строку даты из формата yyyy-mm-dd в dd.mm.yyyy
     * @param {string} date - строка формата yyyy-mm-dd
     * @returns строка формата dd.mm.yyyy
     */
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    /**
     * Фоматирует строку даты из формата dd.mm.yyyy в yyyy-mm-dd
     * @param {string} date - строка формата dd.mm.yyyy
     * @returns строка формата yyyy-mm-dd
     */
    formatDateToDatepicker(date) {
      if (!date) return null;
      const [day, month, year] = date.split(".");
      return `${year}-${month}-${day}`;
    },

    /**
     * Парсит строку формата "2022-05-25T18:40:00" в "18:40 - 2022-05-25"
     * @param {string} date - строка формата "2022-05-25T18:40:00"
     * @returns строка формата "18:40 - 2022-05-25"
     */
    dateFromStringToString(date) {
      return date.substr(11, 5) + " - " + date.substr(0, 10);
    },
    /**
     * Переводит миллисикунды в строку с минутами и секундами
     * @param {number} ms - кол-во миллисикунд
     * @returns строка в формате mm:ss
     */
    fmtMSS(ms) {
      var s = Math.floor(ms / 1000);
      return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    },
    /**
     * Добавляет указанное кол-во дней к дате
     * @param {Date} date - Дата
     * @param {number} daysCount - Кол-во добовляемых дней
     * @returns Новая дата
     */
    addDays(date, daysCount) {
      var result = new Date(date);
      result.setDate(result.getDate() + daysCount);
      return result;
    },
    /**
     * Парсит строку в секунды
     * @param {string} string - строка в формате "hh:ss".
     * @return {number} Кол-во секунд
     */
    parseTimeToInt(string) {
      let array = string.split(":");
      return parseInt(array[0]) * 60 + parseInt(array[1]);
    },
    /**
     * сервер принимает utc, поэтому нужно накинуть GMT
     */
    removeGMT(date) {
      const timeOffset = date.getTimezoneOffset() * 60000;
      const offsetted = new Date(date.getTime() - timeOffset);
      const str = offsetted.toISOString();
      return new Date(str);
    },
    /**
     * сервер отдает utc, поэтому нужно убрать GMT
     */
    addGMT(date) {
      const timeOffset = date.getTimezoneOffset() * 60000;
      const offsetted = new Date(date.getTime() + timeOffset);
      const str = offsetted.toISOString();
      return new Date(str);
    },
  },
  filters: {
    moment(date) {
      let m = moment(date);
      m.locale("ru");
      return m.format("DD.MM.YYYY HH:mm");
    },
  },
};
