import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#000000",
        secondary: "#FFA014",
        accent: "#D9C8BC",
        error: "#F44336",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "FFA014",
        item1: "#cfd8dc",
        item2: "#b2dfdb",
        window: "#dfdfdf",
      },
      dark: {
        primary: "#ffffff",
        secondary: "#FFA014",
        accent: "#141414",
        error: "#F44336",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFA014",
        item1: "#0B0B0B",
        item2: "#080808",
        window: "#1E1E1E",
      },
      options: {
        customProperties: true,
        themeCache: {
          get: (key) => localStorage.getItem(key),
          set: (key, value) => localStorage.setItem(key, value),
        },
      },
    },
  },
});

export default vuetify;
